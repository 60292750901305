import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, IconButton, InputAdornment, Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { closeModal, setMode } from '../../../redux/slices/authModal';
import Iconify from '../../Iconify';
import { FormProvider, RHFCheckbox, RHFTextField } from '../../hook-form';
import { PATH_AUTH } from '../../../routes/paths';


export default function SignInMode() {
    const { login } = useAuth();
    const dispatch = useDispatch();
    const isMountedRef = useIsMountedRef();
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
    });

    const { reset, setError, handleSubmit, formState: { errors, isSubmitting } } = methods;

    const onSubmit = async (data) => {
        try {
            await login(data.email, data.password);
            navigate('/dashboard');
            reset();
            dispatch(closeModal());
        } catch (error) {
            console.error(error);

            if (isMountedRef.current) {
                setError('afterSubmit', { ...error, message: error.message });
            }
        }
    };

    const handleSignUp = () => {
        dispatch(closeModal());
        navigate(PATH_AUTH.signUp);
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                {!!errors.afterSubmit && (
                    <Alert severity="error">
                        {errors.afterSubmit.detail ? errors.afterSubmit.detail : "There was an issue while trying to establish connection. Please contact support."}
                    </Alert>
                )}
                <RHFTextField name="email" label="Email address" />
                <RHFTextField
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <RHFCheckbox name="remember" label="Remember me" />
                <Button onClick={() => dispatch(setMode('forgotPassword'))}>
                    Forgot password?
                </Button>
            </Stack>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Login
            </LoadingButton>
            <Button onClick={handleSignUp} sx={{ mt: 2 }}>
                Don't have an account? Sign Up
            </Button>
        </FormProvider>
    );
}
