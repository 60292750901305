// routes
import { useEffect } from 'react';
import { PATH_DASHBOARD, PATH_STUDENT, PATH_UNIVERSITY, PATH_COMPANY } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const getIconfy = (name) => <Iconify icon={name} width={22} height={22} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),

  // ---------------------------------------
  // Student Icons
  jobs: getIconfy('eva:briefcase-fill'),
  events: getIconfy('carbon:events'),
  opportunities: getIconfy('maki:college'),
  placements: getIconfy('fluent:people-audience-24-filled'),
  internships: getIconfy('fluent:people-team-24-filled'),
  resume: getIconfy('mingcute:document-line'),
  StudentSettings: getIconfy('eva:settings-fill'),
  studentCareerTrack: getIconfy('eva:arrowhead-up-outline'),
  ctroadmap: getIconfy('streamline:arrow-roadmap'),
  test: getIconfy('material-symbols:quiz'),
  // Student CH
  careerHubHome: getIconfy('eva:home-outline'),
  personalityTest: getIconfy('icon-park-outline:thinking-problem'),
  mockInterview: getIconfy('material-symbols:frame-person-mic'),

  // ---------------------------------------
  // Univeristy Icons
  addStudent: getIconfy('eva:person-add-fill'),
  groups: getIconfy('eva:people-fill'),
  studentActivity: getIconfy('eva:activity-fill'),
  uniJobs: getIconfy('eva:briefcase-fill'),
  studentDetails: getIconfy('eva:person-fill'),
  univeristyEvent: getIconfy('material-symbols:event'),
  uniWorkshops: getIconfy('material-symbols:computer-outline'),
  upgrade:getIconfy('eva:arrowhead-up-outline'),
  add:getIconfy('ic:baseline-add'),

  // ---------------------------------------
  // Company Icons
  newJobs: getIconfy('eva:archive-fill'),
  newJobPosting: getIconfy('ion:create-outline'),
  Applicants: getIconfy('et:profile-male'),


  // TPO
  uniListing: getIconfy('la:university'),
  recListing: getIconfy('hugeicons:permanent-job'),
  howit: getIconfy('material-symbols:help'),
  myContacts : getIconfy('material-symbols:contacts'),
  community: getIconfy('wpf:chat'),
  myUniversity : getIconfy('hugeicons:university'),
};

const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRON || '';

// University nav
const universityNavConfig = [
  {
    subheader: 'Home',
    items: [
      {
        title: 'dashboard',
        path: '/dashboard',
        icon: ICONS.dashboard,
      },
      {
        roles: ['university', 'admin'],
        title: 'My University',
        path: '/my-university',
        icon: ICONS.myUniversity,
        info: (
          <Label color="warning" startIcon={<Iconify icon="fluent:new-24-filled" />}>
            NEW
          </Label>
        ),
      },
      {
        title: 'Community',
        path: '/community',
        icon: ICONS.community,
      },
      {
        title: 'My Contacts',
        path: '/my-contacts',
        icon: ICONS.myContacts,
      }
    ],
  },
  {
    subheader: 'Placements',
    items: [
      {
        roles: ['university'],
        title: 'Upgrade',
        path: '/upgrade',
        icon: ICONS.upgrade,
        info: (
          <Label color="success" >
            VP
          </Label>
        ),
      },
      {
        title: 'Campus Recruiter Listing',
        path: 'recruiter/listings',
        icon: ICONS.recListing
      },
      {
        title: 'TPOs Listings',
        path: 'university/listings',
        icon: ICONS.uniListing
      },
      {
        roles: ['university', 'admin'],
        title: 'Directory',
        path: '/directory/recruiter',
        icon: ICONS.groups,
      },
      // {
      //   title: 'Get Updates',
      //   path: '/whatsapp',
      //   icon: ICONS.mail,
      // }
    ],
    //
  },
  {
    subheader: 'For You',
    items: [
      {
        roles: ['university'],
        title: 'Add Credits',
        path: '/checkout',
        icon: ICONS.add,
      },
      {
        title: 'Settings',
        path: 'user/settings',
        icon: ICONS.StudentSettings,
      },
      // {
      //   title: 'How it Works',
      //   path: '/about/how-it-works',
      //   icon: ICONS.howit,
      // }
    ],
  },
];

// if (REACT_APP_ENVIRONMENT === 'production') {
//   // Remove the last three items in navConfig
//   navConfig.splice(-3);
// }

const navConfig = [
  {
    items: universityNavConfig,
  },
];

export default navConfig;
