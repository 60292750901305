import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
 // components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {

  const location = useLocation();


  useEffect(() => {
    // Track page view on route change
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    window.clarity('set', 'pageview', location.pathname + location.search);

  }, [location])
  

  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <PlacementHome />, index: true },

        {
          path: 'dashboard',
          element: (
            <AuthGuard>
              <UserDashboard />
            </AuthGuard>
          ),
        },
        {
          path: 'user/settings',
          element: (
            <AuthGuard>
              <UserSettings />
            </AuthGuard>
          ),
        },
        {
          path: 'user/orders',
          element: (
            <AuthGuard>
              <UserOrders />
            </AuthGuard>
          ),
        },
          
        {path: 'contact/:slug', element: <ContactChatView />},
        {path: 'u/:slug', element: <UniversityProfile />},
        {path: 'upgrade', element: <Upgrade />},
        {path: 'upgrade/complete', element: <PostUpgrade />},
        { path: 'university/listings', element: <UniversityListingsHome /> },
        { path: 'university/listing/add', element: <AddUniversityListings /> },
        {path: 'recruiter/listings', element: <RecruiterListingsInside />},
        { path: 'recruiter/listing/add', element: <AddRecruiterListings /> },
        { path: 'recruiter/listing/:id', element: <RecruiterPostDetail /> },
        { path: 'university/listing/:id/', element: <UniversityPostDetail /> },
        { path: 'listing/pending', element: <PendingPost /> },
        { path: 'listing/self', element: <ViewSelfListing /> },
        { path: 'recruiter/listing/edit/:id', element: <RecEditPost /> },
        { path: 'university/listing/edit/:id', element: <UniEditPost /> },
        {path: 'whatsapp', element: <WhatsappJoin />},
        {path: 'my-contacts', element: <MyContacts />},
        {path : 'my-university', element:( 
          <AuthGuard>
          <MyUniversity />
          </AuthGuard>
        
        )},
        {path: 'my-contacts/company/:id', element: <SingleCompanyContactView />},
        {path: 'company/:id', element: <SingleCompanyView />},

        { path: 'directory', element: <TCDirectory /> },
        { path: 'directory/tpo', element: <TpoPage /> },
        { path: 'directory/company', element: <CompanyPage /> },
        {path: 'directory/recruiter', element: <RecruiterDirectory />},
        {path: 'directory/recruiter/:id', element: <RecruiterDirectoryDetail />},
        {
          path: 'community', element: <CommunitiesPage />
        },
        {
          path: 'campaigns',
          element: <CampaignsList />,
        },
        {
          path: 'checkout',
          element: (
            <AuthGuard>
              <Checkout />
            </AuthGuard>
          ),
        },
        {
          path: 'checkout/success',
          element: (
            <AuthGuard>
              <PaymentSuccess />
            </AuthGuard>
          ),
        },
        {
          path: 'checkout/payment',
          element: (
            <AuthGuard>
              <Payment />
            </AuthGuard>
          ),
        },
        {
          path: 'about',
          children: [
            { path: 'tnc', element: <Tnc /> },
            { path: 'privacy-policy', element: <PrivacyPolicy /> },
            { path: 'refund-policy', element: <RefundPolicy /> },
            { path: 'about-us', element: <AboutUs /> },
            {path: 'contact-us', element: <ContactUs />},
            {path: 'pricing', element: <Pricing />},
            {path:'how-credits', element: <HowCredits />},
            {path:'how-it-works', element: <HowItWorks />}
          ],
        },
        {
          path: 'admin',
          children: [
            {
              path: 'listing/company',
              element: (
                <RoleBasedGuard roles={['admin']}>
                  <AdminCompanyListing />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'listing/university',
              element: (
                <RoleBasedGuard roles={['admin']}>
                  <AdminUniversityListing />
                </RoleBasedGuard>
              ),
            },
          ],
        },
      ],
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <Signup />
            </GuestGuard>   
          ),
        },
        // {
        //   path: 'verify-otp',
        //   element: (
        //     <GuestGuard>
        //       <VerifyOTP />
        //     </GuestGuard>   
        //   ),
        // },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'create-password', element: <NewUserResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'crossing', element: <Crossing /> },
      ],
    },

    // Other
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const StudentLogin = Loadable(lazy(() => import('../pages/auth/StudentLogin')));
const UniversityLogin = Loadable(lazy(() => import('../pages/auth/UniversityLogin')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const Signup = Loadable(lazy(() => import('../pages/auth/Signup')));
const VerifyOTP = Loadable(lazy(()=>import('../pages/auth/OTPVerification')))
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewUserResetPassword = Loadable(lazy(() => import('../pages/auth/NewUserResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// TC
const TCDirectory = Loadable(lazy(() => import('../pages/TPOConnect/directory/DirectoryHome')));
const TpoPage = Loadable(lazy(() => import('../pages/TPOConnect/directory/TpoPage')));
const CompanyPage = Loadable(lazy(() => import('../pages/TPOConnect/directory/CompanyPage')));
const RecruiterDirectory = Loadable(lazy(() => import('../pages/TPOConnect/directory/RecruiterDirectory')));
const RecruiterDirectoryDetail = Loadable(lazy(() => import('../pages/TPOConnect/directory/RecruiterDirectoryDetail')));

const CommunitiesPage = Loadable(lazy(() => import('../pages/TPOConnect/community/CommunitiesPage')));

const WhatsappJoin = Loadable(lazy(() => import('../pages/TPOConnect/whatsapp/JoinCommunity')));
// const MyContacts = Loadable(lazy(() => import('../pages/TPOConnect/MyContacts/MyContacts')));

const Checkout = Loadable(lazy(() => import('../pages/TPOConnect/payment/Checkout'))); 
const Payment = Loadable(lazy(() => import('../pages/TPOConnect/payment/Payment')));
const PaymentSuccess = Loadable(lazy(() => import('../pages/TPOConnect/payment/PaymentSuccess')));

// Placement
const PlacementHome = Loadable(lazy(() => import('../pages/TPOConnect/PlacementConnectHome')));
const UniversityListingsHome = Loadable(lazy(() => import('../pages/TPOConnect/listing/university/UniversityListingsHome')));
const AddRecruiterListings = Loadable(lazy(() => import('../pages/TPOConnect/listing/recruiter/AddRecruiterListing')));
const AddUniversityListings = Loadable(lazy(() => import('../pages/TPOConnect/listing/university/AddUniversityListing')));
const RecruiterListingsInside = Loadable(lazy(() => import('../pages/TPOConnect/listing/recruiter/RecruiterListingInside')));
const ViewSelfListing = Loadable(lazy(() => import('../pages/TPOConnect/listing/ViewSelfListing')));
const RecruiterPostDetail = Loadable(lazy(() => import('../pages/TPOConnect/listing/recruiter/DetailedRecruiterPost')));
const UniversityPostDetail = Loadable(lazy(() => import('../pages/TPOConnect/listing/university/DetailedUniPost')));
const RecEditPost = Loadable(lazy(() => import('../pages/TPOConnect/listing/recruiter/RecEditPost')));
const UniEditPost = Loadable(lazy(() => import('../pages/TPOConnect/listing/university/UniEditPost')));
const UserDashboard = Loadable(lazy(() => import('../pages/TPOConnect/dashboard/UserDashboard')));
const PendingPost = Loadable(lazy(() => import('../pages/TPOConnect/listing/PendingListing')));
const MyContacts = Loadable(lazy(() => import('../pages/TPOConnect/MyContacts/recruiterCRM/ContactsView')));
const SingleCompanyView = Loadable(lazy(() => import('../pages/TPOConnect/MyContacts/recruiterCRM/detail/CompanyDetailView')));
const SingleCompanyContactView = Loadable(lazy(() => import('../pages/TPOConnect/MyContacts/recruiterCRM/ContactDetailView')));
const Tnc = Loadable(lazy(() => import('../pages/TPOConnect/about/Tnc')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/TPOConnect/about/PrivacyPolicy')));
const RefundPolicy = Loadable(lazy(() => import('../pages/TPOConnect/about/RefundPolicy')));
const AboutUs = Loadable(lazy(() => import('../pages/TPOConnect/about/AboutUs')));
const ContactUs = Loadable(lazy(() => import('../pages/TPOConnect/about/ContactUs')));
const Pricing = Loadable(lazy(() => import('../pages/TPOConnect/about/Pricing')));
const HowCredits = Loadable(lazy(() => import('../pages/TPOConnect/about/HowCredits')));
const HowItWorks = Loadable(lazy(() => import('../pages/TPOConnect/about/HowItWorks')));

const AdminCompanyListing = Loadable(lazy(() => import('../pages/tpoadmin/RecListing')));
const AdminUniversityListing = Loadable(lazy(() => import('../pages/tpoadmin/UniListing')));

const UserSettings = Loadable(lazy(() => import('../pages/user/Settings')));
const UserOrders = Loadable(lazy(() => import('../pages/user/Orders')));

const Upgrade = Loadable(lazy(() => import('../pages/TPOConnect/upgrade/Upgrade')));
const PostUpgrade = Loadable(lazy(() => import('../pages/TPOConnect/upgrade/PostUpgrade')));

const MyUniversity = Loadable(lazy(() => import('../pages/TPOConnect/MyOrg/UniversityProfilePage')));
const UniversityProfile = Loadable(lazy(() => import('../pages/TPOConnect/profile/University')));


const CampaignsList = Loadable(lazy(() => import('../pages/TPOConnect/campaign/CampaignList')));

const ContactChatView = Loadable(lazy(() => import('../pages/TPOConnect/contact/ChatInterface')));

// #########################

// GENERAL
const Crossing = Loadable(lazy(() => import('../pages/auth/Crossing')));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
