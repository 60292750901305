// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}


// const ROOT_HOME = '/jj';
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_STUDENT = '/student';
const ROOTS_UNIVERSITY = '/university';
const ROOTS_COMPANY = '/company';
 

// ----------------------------------------------------------------------
 

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  signUp:path(ROOTS_AUTH,'/signup'),
  verifyOtp:path(ROOTS_AUTH,'/verify-otp'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  crossing: path(ROOTS_AUTH, '/crossing'),
};

export const PATH_TC = {
  dashboard: '/dashboard',
  addRecListing:'/recruiter/listing/add',
  addUniListing:'/university/listing/add',
  recPost:(id)=>path(`/recruiter/listing/${id}`),
  uniPost:(id)=>path(`/university/listing/${id}`),
  recEdit:(id)=>path(`/recruiter/listing/edit/${id}`),
  uniEdit:(id)=>path(`/university/listing/edit/${id}`),
  company:(id)=>path(`/company/${id}`),
  directory:'/directory',
  directoryTpo: '/directory/company',
  directoryCompany: '/directory/tpo',
  directoryRecruiter: '/directory/recruiter',
  listingUniversity: 'university/listings',
  community: '/community',
  about: {
    tnc: '/about/tnc',
    privacyPolicy: '/about/privacy-policy',
    pricing: '/about/pricing',
    refundPolicy: '/about/refund-policy',
    aboutUs: '/about/about-us',
    contactUs: '/about/contact-us',
    howCredits: '/about/how-credits',
  },
  listing : {
    recruiter: '/recruiter/listings/',
    university: '/university/listings/',
    self : '/listing/self/',
  },
  myContacts: '/my-contacts',
  pending: '/pending',
  userSetting: '/user/settings',
  userOrders : '/user/orders',
  upgrade: '/upgrade',
  payment: (id) => path(`/checkout/payment/?id=${id}`),
  paymentSuccess: '/checkout/success',
  
};


export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};


export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    crossing: path(ROOTS_DASHBOARD, '/crossing'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_STUDENT = {
  root: ROOTS_STUDENT,
  general: {
    app: path(ROOTS_STUDENT, '/dashboard/app'),
    social: path(ROOTS_STUDENT, '/dashboard/social'),
    onboarding: path(ROOTS_STUDENT, '/dashboard/setup/onboarding'),

    jobs: path(ROOTS_STUDENT, '/dashboard/jobs'),
    jobsApplied: path(ROOTS_STUDENT, '/dashboard/jobs/applied'),
    internships: path(ROOTS_STUDENT, '/dashboard/internships'),
    hiringDrive: path(ROOTS_STUDENT, '/dashboard/hiring-drive'),

    jobView: (id) => path(ROOTS_STUDENT, `/dashboard/jobs/view/${id}`),
    internshipView: (id) => path(ROOTS_STUDENT, `/dashboard/internships/view/${id}`),
    hiringDriveView: (id) => path(ROOTS_STUDENT, `/dashboard/hiring-drive/view/${id}`),

    events: path(ROOTS_STUDENT, '/dashboard/events/view'),
    eventView: (id) => path(ROOTS_STUDENT, `/dashboard/events/view/${id}`),

    opportunities: path(ROOTS_STUDENT, '/dashboard/opportunities/view'),
    opportunityView: (id) => path(ROOTS_STUDENT, `/dashboard/opportunities/view/${id}`),

    feed: path(ROOTS_STUDENT, '/dashboard/feed/view'),
    feedPostView: (id) => path(ROOTS_STUDENT, `/dashboard/feed/view/${id}`),

    workshops: path(ROOTS_STUDENT, '/dashboard/workshops/view'),
    workshopPostView: (id) => path(ROOTS_STUDENT, `/dashboard/workshops/view/${id}`),

    careerTrack: path(ROOTS_STUDENT, '/dashboard/career-track'),
    InterviewQA: path(ROOTS_STUDENT, '/dashboard/career-track/interview-qa'),
  },
  user: {
    // root: path(ROOTS_STUDENT, '/dashboard/user'),
    // new: path(ROOTS_STUDENT, '/dashboard/user/new'),
    // list: path(ROOTS_STUDENT, '/dashboard/user/list'),
    // cards: path(ROOTS_STUDENT, '/dashboard/user/cards'),
    profile: path(ROOTS_STUDENT, '/dashboard/user/profile'),
    editProfile: path(ROOTS_STUDENT, '/dashboard/user/profile/edit'),
    account: path(ROOTS_STUDENT, '/dashboard/user/account'),
    // edit: (name) => path(ROOTS_STUDENT, `/dashboard/user/${name}/edit`),
    // demoEdit: path(ROOTS_STUDENT, `/dashboard/user/reece-chung/edit`),
  },

}

export const PATH_UNIVERSITY = {
  root: ROOTS_UNIVERSITY,
  general: {
    app: path(ROOTS_UNIVERSITY, '/dashboard/app'),
    addStudent: path(ROOTS_UNIVERSITY, '/dashboard/add/student'),
    editStudent: (id) => path(ROOTS_UNIVERSITY, `/dashboard/edit/student/${id}`),
    groups: path(ROOTS_UNIVERSITY, '/dashboard/groups'),
    newGroup: path(ROOTS_UNIVERSITY, '/dashboard/groups/new'),
    studentActivity: path(ROOTS_UNIVERSITY, '/dashboard/student-activity'),
    studentDetails: path(ROOTS_UNIVERSITY, '/dashboard/student-details'),

    jobs: path(ROOTS_UNIVERSITY, '/dashboard/jobs/list'),
    jobsApproval: path(ROOTS_UNIVERSITY, '/dashboard/jobs/approval'),

    hiringDrive: path(ROOTS_UNIVERSITY, '/dashboard/hiring-drive'),
    newHiringDrive: path(ROOTS_UNIVERSITY, '/dashboard/hiring-drive/new'),
    editHiringDrive: (id) => path(ROOTS_UNIVERSITY, `/dashboard/hiring-drive/${id}/edit`),

    rhCompanyList: path(ROOTS_UNIVERSITY, '/dashboard/recruiterhub/company/list'),
    rhMessages: path(ROOTS_UNIVERSITY, '/dashboard/recruiterhub/messages'),

    events: path(ROOTS_UNIVERSITY, '/dashboard/events/view'),
    eventView: (id) => path(ROOTS_UNIVERSITY, `/dashboard/events/view/${id}`),
    eventForm: path(ROOTS_UNIVERSITY, '/dashboard/events/new'),

    feed: path(ROOTS_UNIVERSITY, '/dashboard/feed/view'),
    feedPostView: (id) => path(ROOTS_UNIVERSITY, `/dashboard/feed/view/${id}`),
    feedNewPost: path(ROOTS_UNIVERSITY, '/dashboard/feed/new'),
    feedPostEdit: (id) => path(ROOTS_UNIVERSITY, `/dashboard/feed/edit/${id}`),

    workshops: path(ROOTS_UNIVERSITY, '/dashboard/workshops/view'),
    workshopPostView: (id) => path(ROOTS_UNIVERSITY, `/dashboard/workshops/view/${id}`),
    workshopsNewPost: path(ROOTS_UNIVERSITY, '/dashboard/workshops/new'),
    workshopsEditPost: (id) => path(ROOTS_UNIVERSITY, `/dashboard/workshops/edit/${id}`),
  },
  user: {
    account: path(ROOTS_UNIVERSITY, '/dashboard/user/account'),
  }
}


export const PATH_COMPANY = {
  root: ROOTS_COMPANY,
  general: {
    newJobPosting: path(ROOTS_COMPANY, '/dashboard/jobs/new/'),
    JobsList: path(ROOTS_COMPANY, '/dashboard/jobs/list/'),
    JobView: (name) => path(ROOTS_COMPANY, `/dashboard/jobs/${name}`),
    JobEdit: (name) => path(ROOTS_COMPANY, `/dashboard/jobs/${name}/edit`),

  },
  admin: {
    JobCreate: path(ROOTS_COMPANY, '/dashboard/admin/jobs/new'),
    JobList: path(ROOTS_COMPANY, '/dashboard/admin/jobs/list'),
  }
}

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
