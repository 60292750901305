import React from 'react';
// Import router link
import { Link as RouterLink } from 'react-router-dom';
import { Dialog, DialogContent, Typography, Button, Box, Link, Grid, Chip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Rocket, School, Insights, Group } from '@mui/icons-material';
import { closeModal } from '../../../redux/slices/credits';

export default function CreditInfo() {
  const dispatch = useDispatch();
  const { isCreditModalOpen } = useSelector((state) => state.credits);

  const handleBuyCredits = (amount, price) => {
    if (price === 200) window.open('https://rzp.io/l/UxgfhWH3', '_blank');
    if (price === 790) window.open('https://rzp.io/l/0zpZik6bj', '_blank');
  };

  return (
    <Dialog open={isCreditModalOpen} onClose={() => dispatch(closeModal())} maxWidth="md" fullWidth>
      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Limited Time Offer: Introductory Prices!
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
            Take advantage of our special launch prices. Get your credits now before prices go up!
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleBuyCredits(10, 200)}
                fullWidth
                sx={{
                  fontWeight: 'bold',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <Chip label="20% OFF" color="secondary" size="small" sx={{ position: 'absolute', top: 8, right: 8 }} />
                <Typography variant="h6">10 Credits</Typography>
                <Typography variant="subtitle1">₹200</Typography>
                <Typography variant="caption">
                  <s>₹300</s> (Save ₹100)
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleBuyCredits(50, 790)}
                fullWidth
                sx={{
                  fontWeight: 'bold',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <Chip label="40% OFF" color="secondary" size="small" sx={{ position: 'absolute', top: 8, right: 8 }} />
                <Typography variant="h6">50 Credits</Typography>
                <Typography variant="subtitle1">₹790</Typography>
                <Typography variant="caption">
                  <s>₹1500</s> (Save ₹700)
                </Typography>
                <Typography variant="caption" sx={{ mt: 1 }}>
                  (Best Value!)
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Typography variant="h4" component="h2" sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}>
          Understanding Credits
        </Typography>

        <Typography variant="body1" sx={{ mb: 2 }}>
          Credits are your gateway to accessing premium features on our platform. Here's how you can earn and use them:
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ mb: 1, color: 'secondary.main' }}>
            Earn Credits:
          </Typography>
          <Typography variant="body1">• Add a new listing: Earn credits for contributing to our community.</Typography>
          <Typography variant="body1">
            • Monthly bonus: Get 15 free credits added to your account every month.
          </Typography>
        </Box>

        <Box
          sx={{
            mb: 4,
            p: 4,
            bgcolor: 'primary.main',
            borderRadius: 2,
            color: 'primary.contrastText',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ position: 'absolute', top: -20, right: -20, fontSize: 120, opacity: 0.1 }}>
            <Rocket />
          </Box>
          <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold' }}>
            🚀 Upgrade to VarsityPro
          </Typography>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Get Unlimited Credits & Transform Your University's Career Management
          </Typography>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            {[
              { icon: <School />, text: 'Unlimited access to all premium features' },
              { icon: <Insights />, text: 'Advanced analytics and reporting tools' },
              { icon: <Group />, text: 'Add unlimited team members' },
              { icon: <Rocket />, text: 'AI-powered placement optimization' },
            ].map((item, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  {item.icon}
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {item.text}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Button
            variant="contained"
            color="secondary"
            component={RouterLink}
            to="/upgrade"
            sx={{ fontWeight: 'bold', px: 4, py: 1.5 }}
            onClick={() => dispatch(closeModal())}
          >
            Upgrade to VarsityPro
          </Button>
        </Box>

        <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
          Remember: VarsityPro onboarded universities never have to wait for credits! 🚀
        </Typography>

        <Typography variant="caption" sx={{ display: 'block', mt: 2, color: 'text.secondary' }}>
          *Introductory prices are subject to change. Buy now to lock in these rates!
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
